















































































import Vue from 'vue';
import AnnotationView from './TimeSeriesClassificationView.vue';
import { parseCsv } from './helpers';
import _isEqual from 'lodash/isEqual';
import _indexOf from 'lodash/indexOf';
import _findKey from 'lodash/findKey';
import { mapGetters } from 'vuex';
import { Asset, Label } from '@/types';
import {
  resolveParameters,
  SignalAnnotatorParametersFromSchema,
  SignalAnnotationLabel,
  SignalAnnotationLabelSchema
} from './utils';
import LoadingModal from '../LoadingModal.vue';
import { ANNOTATION_UI_PROPS } from '../annotations/props';

const CSV_MEDIA_TYPE = 'text/csv';

export default Vue.extend({
  name: 'TimeSeriesClassificationController',
  components: {
    AnnotationView,
    LoadingModal
  },
  props: ANNOTATION_UI_PROPS,
  created() {
    this.initialize();
  },
  data: () => ({
    activeTimeSeriesData: null as Record<string, number[]> | null,
    errorLoadingMedia: null as string | null,
    activeAssetAnnotations: null as SignalAnnotationLabel[] | null,
    activeAssetPredictions: null as SignalAnnotationLabel[] | null,
    saving: false,
    errorSaving: null as string | null,
    loading: false,
    showSuccess: false,
    showNothingChanged: false,
    nUnknownLabelsDiscarded: 0
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    progressPercentage(): number {
      return (this.status.annotations * 100.0) / this.status.assets;
    },
    activeAssetIndex(): number {
      return _indexOf(this.assets, this.activeAsset);
    },
    canAnnotate(): boolean {
      return (
        this.task.annotator && this.task.annotator.id === this.currentUser.id
      );
    },
    config(): SignalAnnotatorParametersFromSchema {
      const parameters = this.annotationConfiguration?.parameters || {};
      return resolveParameters(parameters);
    }
  },
  methods: {
    initialize() {
      this.setActiveData();
    },
    nextAsset() {
      const index = this.activeAssetIndex;
      const newIndex = index + 1;
      if (newIndex >= this.assets.length) {
        this.onFinishAnnotating();
        return;
      }
      this.$emit('update:activeAsset', this.assets[newIndex]);
    },
    previousAsset() {
      const index = this.activeAssetIndex;
      const newIndex = index - 1;
      if (newIndex < 0) {
        return;
      }
      this.$emit('update:activeAsset', this.assets[newIndex]);
    },
    parseSignalAnnotationLabelOrUndefined(
      l: any
    ): SignalAnnotationLabel | undefined {
      // Check that the label is even valid, the "label" field could still
      // be unknown to the configuration
      try {
        return SignalAnnotationLabelSchema.passthrough().parse(l);
      } catch (err) {
        console.log(`Invalid label`, err, l);
        return undefined;
      }
    },
    async annotationsFor(
      asset: Asset
    ): Promise<{
      labels: SignalAnnotationLabel[];
      unknownLabels: Label[];
    }> {
      console.log(`Fetching annotations for asset ${asset.id}`);
      const annotation = await this.$api.tasks.getAnnotationForAsset({
        taskId: this.task.id,
        assetId: asset.id
      });

      console.log(`Fetched annotation for asset`, annotation);

      if (!annotation) {
        return { labels: [], unknownLabels: [] };
      }

      const labels = annotation.labels;

      const parsedLabels = labels.map(l => ({
        value: l,
        parsed: this.parseSignalAnnotationLabelOrUndefined(l)
      }));

      const knownLabels = parsedLabels
        .filter(l => !!l.parsed)
        .map(l => l.parsed);

      const unknownLabels = parsedLabels
        .filter(l => !l.parsed)
        .map(l => l.value);

      if (unknownLabels.length > 0) {
        console.warn(`Found unknown intervals: ${unknownLabels.length}`);
      }
      return { labels: knownLabels, unknownLabels };
    },
    async predictionsFor(
      asset: Asset
    ): Promise<SignalAnnotationLabel[] | undefined> {
      console.log(`Fetching predictions for asset ${asset.id}`);
      const prediction = await this.$api.tasks.getPreAnnotationForAsset({
        taskId: this.task.id,
        assetId: asset.id
      });

      if (!prediction) {
        return [];
      }

      // TODO Parse labels here
      return prediction.labels as SignalAnnotationLabel[];
    },
    async setActiveData() {
      const active = this.activeAsset;
      const medias = active.media;
      this.errorLoadingMedia = null;
      this.loading = true;

      this.activeTimeSeriesData = null;
      this.activeAssetAnnotations = null;
      this.activeAssetPredictions = null;

      const csvMediaSlug = _findKey(
        medias,
        val => val.media_type === CSV_MEDIA_TYPE
      );

      if (!csvMediaSlug) {
        this.errorLoadingMedia = `No CSV found in media`;
        return;
      }

      const mediaSlug = csvMediaSlug;

      try {
        const csvBuffer = await this.fetchMedia({
          assetId: active.id,
          mediaSlug,
          mediaType: 'text/csv'
        });
        const utf8decoder = new TextDecoder();

        const csv = utf8decoder.decode(csvBuffer);

        const { labels, unknownLabels } = await this.annotationsFor(
          this.activeAsset
        );

        this.nUnknownLabelsDiscarded = unknownLabels.length;

        this.activeAssetAnnotations = labels;

        this.activeAssetPredictions = await this.predictionsFor(
          this.activeAsset
        );

        this.activeTimeSeriesData = parseCsv(csv);
      } catch (error) {
        this.errorLoadingMedia = error;
      } finally {
        this.loading = false;
      }
    },
    onNext() {
      this.nextAsset();
    },
    onPrev() {
      this.previousAsset();
    },
    async saveAnnotations(annotations: SignalAnnotationLabel[]) {
      const previousAnnotations = this.activeAssetAnnotations;

      if (_isEqual(annotations, previousAnnotations)) {
        console.log('Skipping saving as nothing changed');
        this.showNothingChanged = true;
        return;
      }
      console.log('Saving new annotations', annotations);
      this.saving = true;
      this.errorSaving = null;
      try {
        await this.onAnnotateAsset({
          assetId: this.activeAsset.id,
          labels: annotations
        });
        this.showSuccess = true;
      } catch (err) {
        this.errorSaving = err;
      } finally {
        this.saving = false;
      }
    }
  },
  watch: {
    activeAsset: function() {
      this.initialize();
    }
  }
});
