













































































import Vue, { PropType } from 'vue';
import _indexOf from 'lodash/indexOf';
import _omit from 'lodash/omit';
import LineChart from './LineChart.vue';
import {
  SaveAnnotations,
  SignalAnnotationLabel,
  SignalAnnotatorParametersFromSchema
} from './utils';

export default Vue.extend({
  name: 'TimeSeriesClassificationView',
  components: { LineChart },
  props: {
    assetIndex: {
      type: Number,
      required: true
    },
    assetName: {
      type: String,
      required: true
    },
    canAnnotate: {
      type: Boolean,
      required: true
    },
    numberOfAssets: {
      type: Number,
      required: true
    },
    timeSeriesData: {
      type: Object as PropType<Record<string, number[]>>,
      required: true
    },
    predictions: {
      required: true,
      type: Array as PropType<Array<SignalAnnotationLabel>>
    },
    previousAnnotations: {
      required: true,
      type: Array as PropType<Array<SignalAnnotationLabel>>
    },
    save: {
      required: true,
      type: Function as PropType<SaveAnnotations>
    },
    next: {
      required: true,
      type: Function as PropType<() => void>
    },
    prev: {
      required: true,
      type: Function as PropType<() => void>
    },
    config: {
      required: true,
      type: Object as PropType<SignalAnnotatorParametersFromSchema>
    }
  },
  data: function() {
    return {
      activeData: {
        label: this.config.availableLabels[0].name, // Currently chosen class to be drawn
        selectedIndex: null as number | null // Currently chosen interval
      },
      intervals: null,
      seriesPlots: [],
      rangePlots: [],
      panelsOpen: [] as number[],
      activeLabel: this.config.availableLabels[0].name
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    headers(): string[] {
      return Object.keys(this.timeSeriesData);
    },
    hasPredictions(): boolean {
      return !!this.predictions && this.predictions.length > 0;
    },
    hasPreviousAnnotations(): boolean {
      return !!this.previousAnnotations && this.previousAnnotations.length > 0;
    },
    availableLabels(): string[] {
      return this.config.availableLabels.map(label => label.name);
    }
  },
  methods: {
    openAllPanels() {
      this.panelsOpen = Array.from(
        { length: this.headers.length },
        (_, i) => i
      );
    },
    async onClickNext() {
      await this.saveIfShould();
      this.next();
    },
    async onClickPrev() {
      await this.saveIfShould();
      this.prev();
    },
    onClickSkip() {
      this.next();
    },
    async saveIfShould() {
      if (!this.canAnnotate) {
        console.log(`Skipping saving as user cannot annotate`);
        return;
      }
      const intervals = this.intervals
        .map(interval => ({
          ...interval
        }))
        .map(label => _omit(label, 'selected'));
      await this.save(intervals);
    },
    initializeIntervals() {
      console.log(
        `Initializing view from annotations`,
        this.previousAnnotations
      );
      if (this.hasPreviousAnnotations) {
        this.intervals = this.previousAnnotations.map(label => ({
          ...label,
          selected: false
        }));
      } else if (this.hasPredictions) {
        this.intervals = this.predictions.map(label => ({
          ...label,
          selected: false
        }));
      } else {
        this.intervals = [];
      }
    },
    dataForChannel(channel) {
      return this.timeSeriesData[channel];
    },
    initialize() {
      this.initializeIntervals();
      this.openAllPanels();
    }
  },
  watch: {
    assetIndex: function() {
      this.initialize();
    }
  }
});
