import _unzip from 'lodash/unzip';
import _zipObject from 'lodash/zipObject';

/**
 * Parse CSV to dictionary of key-value pairs.
 * Example result: `{ "v1": [0.2, 0.4], "v3": [1.2, 3.2] }`
 */
export const parseCsv = (csvString: string) => {
  const allLines = csvString.split('\n');
  const headerLine = allLines[0];
  const headers = headerLine.split(',');
  const valueLines = allLines.slice(1);
  const parsedRows = valueLines
    .filter(line => line.length > 0)
    .map(line => line.split(','))
    .map(vals => vals.map(strVal => parseFloat(strVal)));
  const columns = _unzip(parsedRows);
  return _zipObject(headers, columns);
};
