export default function wheelZoomPlugin(
  opts,
  syncedRangedPlots = [],
  syncedSeriesPlots = []
) {
  const factor = opts.factor || 0.75;
  const zoomX = opts.x;
  const zoomY = opts.y;

  let xMin, xMax, yMin, yMax, xRange, yRange;

  function clamp(nRange, nMin, nMax, fRange, fMin, fMax) {
    if (nRange > fRange) {
      nMin = fMin;
      nMax = fMax;
    } else if (nMin < fMin) {
      nMin = fMin;
      nMax = fMin + nRange;
    } else if (nMax > fMax) {
      nMax = fMax;
      nMin = fMax - nRange;
    }

    return [nMin, nMax];
  }

  return {
    hooks: {
      ready: u => {
        // Todo fix workaround
        const p = syncedSeriesPlots[0]; // u
        xMin = p.scales.x.min;
        xMax = p.scales.x.max;
        yMin = p.scales.y.min;
        yMax = p.scales.y.max;

        xRange = xMax - xMin;
        yRange = yMax - yMin;

        console.log(xRange, xMin, xMax);

        const plot = u.root.querySelector('.u-over');
        const rect = plot.getBoundingClientRect();

        plot.addEventListener('dblclick', e => {
          e.preventDefault();
        });

        // wheel scroll zoom
        plot.addEventListener('wheel', e => {
          e.preventDefault();

          const { left, top } = u.cursor;

          const leftPct = left / rect.width;
          const btmPct = 1 - top / rect.height;
          const xVal = u.posToVal(left, 'x');
          const yVal = u.posToVal(top, 'y');
          const oxRange = u.scales.x.max - u.scales.x.min;
          const oyRange = u.scales.y.max - u.scales.y.min;

          const nxRange = e.deltaY < 0 ? oxRange * factor : oxRange / factor;
          let nxMin = xVal - leftPct * nxRange;
          let nxMax = nxMin + nxRange;
          [nxMin, nxMax] = clamp(nxRange, nxMin, nxMax, xRange, xMin, xMax);

          const nyRange = e.deltaY < 0 ? oyRange * factor : oyRange / factor;
          let nyMin = yVal - btmPct * nyRange;
          let nyMax = nyMin + nyRange;
          [nyMin, nyMax] = clamp(nyRange, nyMin, nyMax, yRange, yMin, yMax);

          u.batch(() => {
            for (let i = 0; i <= syncedRangedPlots.length; i++) {
              const plot = syncedRangedPlots[i];

              if (zoomX) {
                if (plot != null) {
                  const left = Math.round(plot.valToPos(nxMin, 'x'));
                  const right = Math.round(plot.valToPos(nxMax, 'x'));

                  plot.setSelect({
                    left: left,
                    width: right - left
                  });
                }
              }
            }

            for (let i = 0; i <= syncedSeriesPlots.length; i++) {
              const plot = syncedSeriesPlots[i];
              if (plot != null) {
                if (zoomX) {
                  plot.setScale('x', {
                    min: nxMin,
                    max: nxMax
                  });
                }

                if (zoomY) {
                  plot.setScale('y', {
                    min: nxMin,
                    max: nxMax
                  });
                }
              }
            }
          });
        });
      }
    }
  };
}
