








































import Vue, { PropType } from 'vue';
// eslint-disable-next-line
// @ts-ignore
import uPlot from 'uplot';
import wheelZoomPlugin from './uPlotPlugins/WheelZoomPlugin';
import editableSelectionPlugin from './uPlotPlugins/EditableSelectionPlugin';
import annotationsPlugin from './uPlotPlugins/AnnotationsPlugin';
import {
  SignalAnnotationLabel,
  SignalAnnotatorParametersFromSchema
} from './utils';
export default Vue.extend({
  props: {
    timeseries: Array as PropType<number[]>,
    labels: Array as PropType<string[]>, // Available labels to select
    annotations: Array as PropType<SignalAnnotationLabel[]>,
    activeData: Object as PropType<{ label?: string; selectedIndex?: number }>,
    rangePlots: {
      type: Array,
      default: () => []
    },
    seriesPlots: {
      type: Array,
      default: () => []
    },
    config: {
      type: Object as PropType<SignalAnnotatorParametersFromSchema>,
      required: true
    },
    activeLabel: {
      type: String,
      required: true
    }
  },
  computed: {
    selectedLabel: {
      get(): number {
        return this.config.availableLabels
          .map(label => label.name)
          .indexOf(this.activeData.label);
      },
      set(value: number): void {
        const newLabel = this.config.availableLabels.map(lab => lab.name)[
          value
        ];
        // eslint-disable-next-line vue/no-mutating-props
        this.activeData.label = newLabel; // TODO Is this dangerous?
        this.$emit('update:activeData', this.activeData);
      }
    },
    timeseriesXY(): [number[], number[]] {
      return [
        Array.from({ length: this.timeseries.length }, (v, k) => k + 1),
        this.timeseries
      ];
    },
    rangePlotOps(): any {
      const ops = {
        width: 900,
        height: 100,
        cursor: {
          x: false,
          y: false,
          points: {
            show: false
          },
          drag: {
            setScale: false,
            setSelect: true,
            x: true,
            y: false
          }
        },
        legend: {
          show: false
        },
        scales: {
          x: {
            time: false
          }
        },
        series: [
          {},
          {
            stroke: 'black'
          }
        ],
        plugins: [
          editableSelectionPlugin(
            this.seriesPlots,
            this.rangePlots,
            this.timeseries.length
          )
        ]
      };
      return ops;
    },
    seriesPlotOps(): any {
      const ops = {
        width: 900,
        height: 200,
        cursor: {
          drag: {
            x: true,
            y: false,

            setScale: false,
            setSelect: true
          },
          sync: {
            key: 'seriesPlotKey'
          }
        },
        scales: {
          x: {
            time: false
          }
        },
        plugins: [
          annotationsPlugin(this.annotations, this.activeData, this.config),
          wheelZoomPlugin(
            { factor: 0.75, x: true, y: false },
            this.rangePlots,
            this.seriesPlots
          )
        ],
        series: [
          {
            label: 'x'
          },
          {
            label: 'y',
            stroke: 'black'
          }
        ],
        hooks: {}
      };
      return ops;
    }
  },
  data: function() {
    return {
      seriesPlot: null,
      rangePlot: null,
      activeFilter: 'Any',
      filterItems: ['Any']
    };
  },
  mounted() {
    this.seriesPlot = new uPlot(
      this.seriesPlotOps,
      this.timeseriesXY,
      this.$refs['seriesPlot']
    );

    this.rangePlot = new uPlot(
      this.rangePlotOps,
      this.timeseriesXY,
      this.$refs['rangePlot']
    );
    // eslint-disable-next-line vue/no-mutating-props
    this.seriesPlots.push(this.seriesPlot);
    // eslint-disable-next-line vue/no-mutating-props
    this.rangePlots.push(this.rangePlot);
  },
  methods: {
    deleteInterval() {
      if (this.activeData.selectedIndex != null) {
        const index = this.activeData.selectedIndex;
        // eslint-disable-next-line vue/no-mutating-props
        this.annotations.splice(index, 1);
        // eslint-disable-next-line vue/no-mutating-props
        this.activeData.selectedIndex = null;
        this.seriesPlot.redraw();
      }
    },
    changeLabel() {
      if (this.activeData.selectedIndex != null) {
        const index = this.activeData.selectedIndex;
        // eslint-disable-next-line vue/no-mutating-props
        this.annotations[index].label = this.activeLabel;
        this.seriesPlot.redraw();
      }
    }
  }
});
