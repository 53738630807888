export default function editableSelectionPlugin(
  syncedSeriesPlots = [],
  syncedRangePlots = [],
  initXmax = 100
) {
  let x0 = null;
  let lft0 = null;
  let wid0 = null;
  const range = { left: null, width: null };
  const minMax = { min: null, max: null };

  const initXmin = 1;

  let leftHandle = null;
  let rightHandle = null;

  function debounce(fn) {
    let raf;

    return (...args) => {
      if (raf) return;

      raf = requestAnimationFrame(() => {
        fn(...args);
        raf = null;
      });
    };
  }

  function select(newLft, newWid, plots) {
    range.left = newLft;
    console.log(newLft);
    range.width = newWid;
    for (let i = 0; i <= plots.length; i++) {
      const plot = plots[i];
      if (plot != null) {
        plot.setSelect(range, false);
      }
    }
  }

  function zoomSynced(u, newLft, newWid, plots) {
    minMax.min = u.posToVal(newLft, 'x');
    minMax.max = u.posToVal(newLft + newWid, 'x');
    for (let i = 0; i <= plots.length; i++) {
      const plot = plots[i];
      if (plot != null) {
        plot.setScale('x', minMax);
      }
    }
  }

  function placeDiv(par, cls) {
    const el = document.createElement('div');
    el.classList.add(cls);
    par.appendChild(el);
    return el;
  }

  function on(ev, el, fn) {
    el.addEventListener(ev, fn);
  }

  function off(ev, el, fn) {
    el.removeEventListener(ev, fn);
  }

  function bindMove(u, e, onMove) {
    x0 = e.clientX;
    lft0 = u.select.left;
    wid0 = u.select.width;
    const _onMove = debounce(onMove);
    const doc = u.root;
    on('mousemove', doc, _onMove);
    on('mouseup', doc, e => {
      off('mousemove', doc, _onMove);
    });

    e.stopPropagation();
  }

  function update(u, newLft, newWid) {
    // Keep selection in bounds
    if (newWid < 0) {
      newWid = 0;
    } else if (newWid > u.bbox.width) {
      newWid = u.bbox.width;
    }

    const diff = u.bbox.width - (newLft + newWid);

    if (diff < 0) {
      if (newWid > range.width) {
        newWid = range.width;
      } else {
        newLft += diff;
      }
    }

    if (newLft < 0) {
      newLft = 0;
      newWid = range.width;
    }

    if (newWid <= 0 && range.left < newLft) {
      newLft = range.left;
    }

    select(newLft, newWid, syncedRangePlots);
    zoomSynced(u, newLft, newWid, syncedSeriesPlots);
  }

  function initSelector(u) {
    const left = Math.round(u.valToPos(initXmin, 'x'));
    const width = Math.round(u.valToPos(initXmax, 'x')) - left;
    const height = u.bbox.height / devicePixelRatio;
    u.setSelect({ left, width, height }, false);

    const sel = u.root.querySelector('.u-select');

    on('mousedown', sel, e => {
      bindMove(u, e, e => update(u, lft0 + (e.clientX - x0), wid0));
    });

    leftHandle = placeDiv(sel, 'u-grip-l');
    rightHandle = placeDiv(sel, 'u-grip-r');

    on('mousedown', leftHandle, e => {
      bindMove(u, e, e =>
        update(u, lft0 + (e.clientX - x0), wid0 - (e.clientX - x0))
      );
    });

    on('mousedown', rightHandle, e => {
      bindMove(u, e, e => update(u, lft0, wid0 + (e.clientX - x0)));
    });

    zoomSynced(u, u.select.left, u.select.width, syncedSeriesPlots);
  }

  function onSelection(u) {
    zoomSynced(u, u.select.left, u.select.width, syncedSeriesPlots);
  }

  return {
    hooks: {
      ready: initSelector,
      setSelect: onSelection
    }
  };
}
