export default function annotationsPlugin(annotations, activeData, config) {
  let inFocus = false;

  const FOCUS_STROKE = '#0000FF';
  const LINE_WIDTH = 3;
  const FOCUS_LINE_WIDTH = 5;
  const LINE_ALPHA = 1.0;

  const MIN_SELECTION = 0.5;

  function on(ev, el, fn) {
    el.addEventListener(ev, fn);
  }

  function inBounds(u, value) {
    return value >= u.scales.x.min && value <= u.scales.x.max;
  }

  function fillsBounds(u, from, to) {
    return from <= u.scales.x.min && to >= u.scales.x.max;
  }

  function intervalInBounds(u, from, to) {
    return inBounds(u, from) || inBounds(u, to) || fillsBounds(u, from, to);
  }

  function hexToRgb(hex, alpha) {
    if (hex.charAt(0) == '#') {
      hex = hex.substring(1);
    }
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r},${g},${b},${alpha})`;
  }

  function drawInterval(u, from, to, color, hasFocus) {
    const { height, top, left } = u.bbox;

    const start = Math.max(u.valToPos(from, 'x', true), left);
    const end = u.valToPos(to, 'x', true);

    u.ctx.save();

    let lineWidth = LINE_WIDTH;
    let strokeStyle = hexToRgb(color, LINE_ALPHA);

    if (hasFocus) {
      lineWidth = FOCUS_LINE_WIDTH;
      strokeStyle = FOCUS_STROKE;
    }

    u.ctx.fillStyle = hexToRgb(color, 0.25);
    u.ctx.fillRect(start, top, end - start, height);

    u.ctx.lineWidth = lineWidth;
    u.ctx.strokeStyle = strokeStyle;
    u.ctx.strokeRect(start, top, end - start, height);

    u.ctx.restore();
  }

  function labelToColor(label) {
    if (label == null) {
      return '#9AB87A';
    }
    const matchingLabels = config.availableLabels.filter(
      lab => lab.name === label
    );
    if (matchingLabels.length > 0) {
      return matchingLabels[0]['color'];
    }
    return '#9AB87A';
  }

  function createAnnotation(u) {
    if (inFocus) {
      const x = u.posToVal(u.select.left, 'x');
      const xt = u.posToVal(u.select.left + u.select.width, 'x');
      if (Math.abs(xt - x) >= MIN_SELECTION) {
        const annotation = {
          from: x,
          to: xt,
          selected: false,
          label: activeData.label
        };
        console.log(`Created annotation`, annotation);
        annotations.push(annotation);
      }
    }
    // Deselect
    u.setSelect({ width: 0 }, false);
  }

  function drawAnnotation(u, annotation, color = null) {
    if (intervalInBounds(u, annotation.from, annotation.to)) {
      const labelColor = color ? color : labelToColor(annotation.label);

      drawInterval(
        u,
        annotation.from,
        annotation.to,
        labelColor,
        annotation.selected
      );
    }
  }

  function drawIntevals(u) {
    // Draw annotations if selection mode is not active

    if (u.select.width == 0) {
      for (let i = 0; i < annotations.length; i++) {
        if (i != activeData.hoverIndex && i != activeData.selectedIndex) {
          const annotation = annotations[i];

          drawAnnotation(u, annotation);
        }
      }

      // Draw selected annotation last (on top)

      if (activeData.hoverIndex != null) {
        const annotation = annotations[activeData.hoverIndex];
        drawAnnotation(u, annotation, '#808080');
      }

      if (activeData.selectedIndex != null) {
        const annotation = annotations[activeData.selectedIndex];
        drawAnnotation(u, annotation, '#000000');
      }
    }
  }

  function selectInterval(u) {
    const selectedWidth = null;
    let newSelection = null;
    const margin = LINE_WIDTH * 0.5 * 2;
    const cursPos = u.cursor.left;

    for (let i = 0; i < annotations.length; i++) {
      const annotation = annotations[i];
      const from = u.valToPos(annotation.from, 'x');
      const to = u.valToPos(annotation.to, 'x');

      if (
        (cursPos >= from - margin && cursPos <= from + margin) ||
        (cursPos >= to - margin && cursPos <= to + margin)
      ) {
        newSelection = i;
      } else {
        annotations[i].selected = false;
      }
    }

    if (newSelection != null) {
      if (
        activeData.hoverIndex != null &&
        activeData.hoverIndex != newSelection
      ) {
        annotations[activeData.hoverIndex].selected = false;
      }
      activeData.hoverIndex = newSelection;
      annotations[activeData.hoverIndex].selected = false;
    } else {
      activeData.hoverIndex = null;
    }

    u.redraw();
  }

  function init(u) {
    on('mouseenter', u.root, e => {
      inFocus = true;
    });

    on('mouseleave', u.root, e => {
      inFocus = false;
    });
    on('click', u.root, e => {
      if (activeData.hoverIndex != null) {
        activeData.selectedIndex = activeData.hoverIndex;
        activeData.label = annotations[activeData.selectedIndex].label;
      } else {
        activeData.selectedIndex = null;
      }
      u.redraw();
    });
  }

  return {
    hooks: {
      ready: init,
      drawClear: drawIntevals,
      setCursor: selectInterval,
      setSelect: createAnnotation
    }
  };
}
